const Colors = {
  primary: "#1E90FF",
  primaryHalf: "rgba(30, 144, 255, 0.5)",
  white: "#ffffff",
  black: "#000000",
  gray: "#bdc3c7",
  darkGray: "#7f8c8d",
  lightGray: "#EDEEF0",

  success: "#28a745",
  warning: "#f1c40f",
  error: "#e74c3c",
  info: "#3498db",

  shadow: "rgba(0, 0, 0, 0.1)",
  border: "#e0e0e0",
};

export default Colors;
