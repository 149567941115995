import React, { useState, useEffect } from "react";
import { useModal } from "../contexts/ModalContext";
import Events from "../constants/Events";
import mixpanel from "mixpanel-browser";

const ResultCard = ({ img, title, description }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { openModal } = useModal();

  const styles = {
    card: {
      position: "relative",
      width: "100%",
      height: "400px",
      borderRadius: "24px",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: "24px",
      backgroundImage: `url(${img})`,
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      cursor: "pointer",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%)", // Darker bottom part
      zIndex: 1,
    },
    content: {
      position: "relative",
      zIndex: 2,
      padding: "20px",
      color: "white",
      textAlign: "center",
    },
    title: {
      margin: 0,
      fontSize: "22px",
      fontWeight: "bold",
    },
    description: {
      margin: "10px 0 0",
      fontSize: "16px",
    },
  };

  const handleCardClick = (serviceName) => {
    const modalMessage = `¿Quieres agendar ${serviceName}?`;
    openModal(modalMessage, "success", serviceName);
    mixpanel.track(Events.PORTFOLIO_CARD_CLIKED, {
      clicked_portfolio_card: serviceName,
    });
  };

  return (
    <div
      style={styles.card}
      onMouseEnter={(e) => {
        if (!isMobile) {
          e.currentTarget.style.transform = "translateY(-10px)";
          e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
        }
      }}
      onMouseLeave={(e) => {
        if (!isMobile) {
          e.currentTarget.style.transform = "translateY(0)";
          e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.15)";
        }
      }}
      onClick={() => handleCardClick(title)}
    >
      <div style={styles.overlay}></div>
      <div style={styles.content}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default ResultCard;
