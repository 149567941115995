import React from "react";
import CoreValuesList from "../components/CoreValuesList";
import BrandSection from "../components/BrandSection";
import SplitTitle from "../components/SplitTitle";
import HeroSection from "../components/HeroSection";
import AboutUsSection from "./AboutUsSection";
import CategoryList from "../components/CategoryList";
import TestimonialList from "../components/TestimonialList";
import EmailInputSection from "../components/EmailInputSection";
import GallerySection from "../components/GallerySection";
import FaqSection from "./FaqSection";
import Colors from "../constants/Colors";

const HomePage = () => {
  return (
    <div style={{ backgroundColor: Colors.lightGray }}>
      {" "}
      {/* Apply background color here */}
      <HeroSection />
      <SplitTitle normalText="Te Presentamos" boldText="Ikada" />
      <AboutUsSection />
      <SplitTitle normalText="Cómo" boldText="Funciona?" />
      <CoreValuesList />
      <SplitTitle
        normalText="Siempre Utilizamos"
        boldText="Marcas Confiables"
      />
      <BrandSection />
      <SplitTitle normalText="Nuestros" boldText="Trabajos" />
      <GallerySection />
      <SplitTitle
        normalText="Explora Nuestra"
        boldText="Categoría de Servicios"
      />
      <CategoryList />
      <SplitTitle normalText="Qué Dicen Nuestros" boldText="Clientes Felices" />
      <TestimonialList />
      <SplitTitle normalText="Preguntas" boldText="Frecuentes" />
      <FaqSection />
      <SplitTitle
        normalText="Suscríbete y Recibe"
        boldText="Promociones y Descuentos"
      />
      <EmailInputSection />
    </div>
  );
};

export default HomePage;
