import React from "react";
import { Link, useLocation } from "react-router-dom";
import WhatsAppButton from "./WhatsAppButton";
import Colors from "../constants/Colors";
import "./DesktopHeader.css";
import Logo from "./Logo";

const DesktopHeader = () => {
  const location = useLocation();

  const styles = {
    header: {
      height: "80px",
      fontFamily: '"Poppins", sans-serif',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 40px",
      backgroundColor: Colors.white,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
    },
    headerLeft: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
    },
    headerCenter: {
      flex: 2,
      display: "flex",
      justifyContent: "center",
    },
    headerRight: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "center",
    },
    menuLink: {
      position: "relative",
      textDecoration: "none",
      margin: "0 15px",
      color: "rgba(49, 57, 79, 0.5)",
      fontWeight: "bold",
      transition: "color 0.3s ease",
    },
    activeLink: {
      color: "rgba(49, 57, 79, 1)",
    },
  };

  return (
    <header style={styles.header}>
      <div style={styles.headerLeft}>
        <Logo src="logo.png" altText="IKADA" linkTo="/" />
      </div>
      <div style={styles.headerCenter}>
        <nav>
          <Link
            to="/services"
            style={{
              ...styles.menuLink,
              ...(location.pathname === "/services" ? styles.activeLink : {}),
            }}
            className={`menu-link ${
              location.pathname === "/services" ? "active" : ""
            }`}
          >
            Servicios
          </Link>
          <Link
            to="/resultados"
            style={{
              ...styles.menuLink,
              ...(location.pathname === "/resultados" ? styles.activeLink : {}),
            }}
            className={`menu-link ${
              location.pathname === "/resultados" ? "active" : ""
            }`}
          >
            Resultados
          </Link>
        </nav>
      </div>
      <div style={styles.headerRight}>
        <WhatsAppButton
          number="573004567890"
          message="¡Hola! ¿En qué podemos ayudarte?"
        />
      </div>
    </header>
  );
};

export default DesktopHeader;
