import React from "react";
import "./AboutUsSection.css";
import houseIcon from "../images/house.svg";
import peopleGroupIcon from "../images/people_group.svg";
import paymentIcon from "../images/payment.svg";

const AboutUsSection = () => {
  return (
    <div className="about-us-section" data-aos="fade-up">
      <div className="about-us-collage">
        <div className="collage-image collage-image-1"></div>
        <div className="collage-image collage-image-2"></div>
        <div className="collage-image collage-image-3"></div>
      </div>
      <div className="about-us-info">
        <div className="info-item">
          <img src={houseIcon} alt="Star Icon" className="info-icon" />
          <div className="info-text">
            <h3 className="info-title">Servicios para tu Hogar</h3>
            <p className="info-description">
              Cuidamos cada rincón de tu hogar con servicios especializados para
              mantenimiento y mejora. Todo agendado de manera rápida y sencilla.
            </p>
          </div>
        </div>
        <div className="info-item">
          <img src={peopleGroupIcon} alt="Star Icon" className="info-icon" />
          <div className="info-text">
            <h3 className="info-title">Acompañamiento de Principio a Fin</h3>
            <p className="info-description">
              Nuestro equipo estará contigo en cada paso del proceso,
              asegurándose de que cada detalle sea manejado con la máxima
              dedicación y cuidado.
            </p>
          </div>
        </div>
        <div className="info-item">
          <img src={paymentIcon} alt="Star Icon" className="info-icon" />
          <div className="info-text">
            <h3 className="info-title">Pagos Flexibles y Modernos</h3>
            <p className="info-description">
              Ofrecemos diversas opciones de pago que se adaptan a ti. Desde
              transferencias bancarias, hasta pagos seguros en línea con PayPal
              y Stripe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
