import React, { useEffect, useState } from "react";
import SplitTitle from "../components/SplitTitle";
import LegalContent from "../components/LegalContent";
import termsAndConditionsData from "../data/terms_and_conditions.json";
import privacyPolicyData from "../data/privacy_policy.json";

const LegalPage = ({ useTerms }) => {
  const [content, setContent] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (useTerms) {
      setContent(termsAndConditionsData.sections);
    } else {
      setContent(privacyPolicyData.sections);
    }
    window.scrollTo(0, 0);
  }, [useTerms]);

  const pageContainerStyle = {
    padding: isMobile ? "0 16px" : "0 40px",
    paddingBottom: isMobile ? "16px" : "40px",
  };

  return (
    <div style={pageContainerStyle}>
      <SplitTitle
        normalText={useTerms ? "Términos y" : "Política de"}
        boldText={useTerms ? "Condiciones" : "Privacidad"}
      />
      <LegalContent sections={content} />
    </div>
  );
};

export default LegalPage;
