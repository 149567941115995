import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SplitTitle from "../components/SplitTitle";
import CategoryList from "../components/CategoryList";
import ServiceList from "../components/ServiceList";
import categoriesData from "../data/categories.json";

const pageContainerStyle = {
  padding: "0 0",
};

const ServicesPage = () => {
  const location = useLocation();
  const serviceListRef = useRef(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get("categoryId");
    setSelectedCategoryId(categoryId || null);

    if (categoryId) {
      const category = categoriesData.find(
        (category) => category.id === parseInt(categoryId)
      );
      if (category) {
        setSelectedCategoryName(category.name);
      } else {
        setSelectedCategoryName("");
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedCategoryId && serviceListRef.current) {
      const serviceListTop =
        serviceListRef.current.getBoundingClientRect().top + window.scrollY;
      const isMobile = window.innerWidth <= 480;
      const offsetPosition = serviceListTop - (isMobile ? 280 : 240);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [selectedCategoryId]);

  return (
    <div style={pageContainerStyle}>
      <SplitTitle
        normalText="Explora Nuestra"
        boldText="Categoría de Servicios"
      />
      <CategoryList setSelectedCategoryId={setSelectedCategoryId} />
      <SplitTitle
        normalText="Te Ofrecemos"
        boldText={
          selectedCategoryId
            ? `Servicios Profesionales en ${selectedCategoryName}`
            : "Servicios Profesionales"
        }
      />
      <div ref={serviceListRef}>
        <ServiceList categoryId={selectedCategoryId} />
      </div>
    </div>
  );
};

export default ServicesPage;
