import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Hotjar from "@hotjar/browser";
import mixpanel from "mixpanel-browser";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import CookieConsent from "react-cookie-consent";
import HomePage from "./components/HomePage";
import ServicesPage from "./components/ServicesPage";
import GlobalModal from "./components/GlobalModal";
import { ModalProvider } from "./contexts/ModalContext";
import LegalPage from "./components/LegalPage";
import ScrollToTop from "./components/ScrollToTop";
import ResultPage from "./components/ResultPage";

const App = () => {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyB8OU_eG7n4YHYYFO8dzahj8ND7EtWGTHU",
      authDomain: "ikada-2469a.firebaseapp.com",
      projectId: "ikada-2469a",
      storageBucket: "ikada-2469a.appspot.com",
      messagingSenderId: "225559886871",
      appId: "1:225559886871:web:a6f98178c0f8c92fdc3052",
      measurementId: "G-JHPRRDG82J",
    };
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    AOS.init({ duration: 1000 });
    const siteId = 5140564;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
    mixpanel.init("81c25e82bcf0d0d70b3cda832e2ad43f", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <div className="App">
      <ModalProvider>
        <Router>
          <ScrollToTop />
          <div className="container">
            <Header />
            <div className="header-height" />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/resultados" element={<ResultPage />} />
              <Route
                path="/terms-and-conditions"
                element={<LegalPage useTerms={true} />}
              />
              <Route
                path="/privacy-policy"
                element={<LegalPage useTerms={false} />}
              />
            </Routes>
          </div>
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText="Entendido"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              color: "#4e503b",
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "2px",
            }}
            expires={150}
          >
            Este sitio web utiliza cookies para mejorar su experiencia de
            usuario.{" "}
            <a href="/privacy-policy" style={{ color: "#FFD700" }}>
              Consultar Políticas de Privacidad
            </a>
          </CookieConsent>
          <GlobalModal />
        </Router>
      </ModalProvider>
    </div>
  );
};

export default App;
