import React, { useState, useEffect } from "react";
import CategoryCard from "./CategoryCard";
import categoriesData from "../data/categories.json";

const images = {
  "category_interior.jpg": require("../images/category_interior.jpg"),
  "category_carpentry.jpg": require("../images/category_carpentry.jpg"),
  "category_plumbing.jpg": require("../images/category_plumbing.jpg"),
  "category_gardening.jpg": require("../images/category_gardening.jpg"),
  "category_painting.jpg": require("../images/category_painting.jpg"),
  "category_electricity.jpg": require("../images/category_electricity.jpg"),
  "category_cleaning.jpg": require("../images/category_cleaning.jpg"),
};

const CategoryList = ({ setSelectedCategoryId }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    categoryList: {
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll",
      padding: isMobile ? "0 8px" : "0 32px",
      scrollbarWidth: "none", // hides scrollbar in Firefox
    },
    noScrollbar: {
      display: "none",
    },
  };

  return (
    <div
      className="category-list"
      style={styles.categoryList}
      data-aos="fade-up"
    >
      {categoriesData.map((category, index) => (
        <CategoryCard
          key={category.id}
          title={category.name}
          description={`Servicios de ${category.name}.`}
          image={images[category.image]} // Use preloaded image
          categoryId={category.id}
          onClick={() => setSelectedCategoryId(category.id)}
          style={index === 0 ? { marginLeft: 0 } : null}
        />
      ))}
    </div>
  );
};

export default CategoryList;
