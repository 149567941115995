import React, { useState, useEffect } from "react";
import ResultCard from "../components/ResultCard";

import result1 from "../images/showcase_1.jpg";
import result2 from "../images/showcase_2.jpg";
import result3 from "../images/showcase_3.jpg";
import result4 from "../images/showcase_4.jpg";
import result5 from "../images/showcase_5.jpg";
import result6 from "../images/showcase_6.jpg";
import result7 from "../images/showcase_7.jpg";
import result8 from "../images/showcase_8.jpg";
import result9 from "../images/showcase_9.jpg";
import result10 from "../images/showcase_10.jpg";

const mockData = [
  {
    img: result1,
    title: "Diseño de Cocinas",
    description:
      "Diseñamos y construimos una cocina moderna, maximizando el espacio disponible e integrando acabados personalizados.",
  },
  {
    img: result2,
    title: "Diseño de Oficinas",
    description:
      "Rediseñamos un espacio de oficina para mejorar la productividad y el confort, creando estaciones de trabajo ergonómicas y un ambiente moderno.",
  },
  {
    img: result3,
    title: "Diseño de Sala",
    description:
      "Transformamos una sala tradicional en un espacio acogedor y contemporáneo, utilizando materiales de alta calidad y un diseño moderno.",
  },
  {
    img: result4,
    title: "Instalación de Paneles Solares",
    description:
      "Llevamos a cabo la instalación de paneles solares en una residencia familiar, optimizando el uso de energía renovable y reduciendo los costos energéticos.",
  },
  {
    img: result5,
    title: "Diseño de Espacios Exteriores",
    description:
      "Diseñamos un espacio exterior con un toque rústico y acogedor, ideal para reuniones al aire libre y eventos familiares.",
  },
  {
    img: result6,
    title: "Remodelación de Cocinas",
    description:
      "Realizamos la remodelación completa de una cocina, integrando acabados de alta calidad y optimizando la distribución para mayor funcionalidad.",
  },
  {
    img: result7,
    title: "Construcción de Escaleras",
    description:
      "Llevamos a cabo la construcción de escaleras modernas en una residencia, utilizando materiales duraderos y diseño personalizado para maximizar el espacio.",
  },
  {
    img: result8,
    title: "Decoración de Interiores",
    description:
      "Decoramos un interior residencial, creando un ambiente sofisticado con muebles contemporáneos y una paleta de colores cálidos.",
  },
  {
    img: result9,
    title: "Diseño de Jardines",
    description:
      "Transformamos un jardín interior, utilizando plantas autóctonas y creando un espacio relajante con detalles modernos.",
  },
  {
    img: result10,
    title: "Construcción de Escaleras",
    description:
      "Realizamos la construcción de una escalera flotante en un área residencial, utilizando madera de alta calidad y un diseño elegante.",
  },
  {
    img: result1,
    title: "Remodelación de Cocinas",
    description:
      "Diseñamos y construimos una cocina moderna, maximizando el espacio disponible e integrando acabados personalizados y electrodomésticos de última generación.",
  },
  {
    img: result2,
    title: "Diseño de Oficinas",
    description:
      "Rediseñamos un espacio de oficina para mejorar la productividad y el confort, creando estaciones de trabajo ergonómicas y un ambiente moderno.",
  },
];

const ResultPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      flexWrap: isMobile ? "nowrap" : "wrap",
      gap: isMobile ? "16px" : "24px",
      justifyContent: "center",
      padding: isMobile ? "16px" : "40px",
    },
    cardWrapper: {
      flex: isMobile ? "1 1 100%" : "1 1 calc(33.333% - 24px)",
      maxWidth: isMobile ? "100%" : "calc(33.333% - 24px)",
    },
  };

  return (
    <div style={styles.container}>
      {mockData.map((result, index) => (
        <div key={index} style={styles.cardWrapper}>
          <ResultCard
            img={result.img}
            title={result.title}
            description={result.description}
          />
        </div>
      ))}
    </div>
  );
};

export default ResultPage;
