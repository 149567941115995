import React from "react";

const BrandCard = ({
  imageUrl,
  linkUrl,
  disableClick = true,
  adjustWidth = "100%",
  adjustHeight = "auto",
}) => {
  const cardStyle = {
    display: "inline-block",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    width: "80px", // Ensure consistent width for all icons
    height: "40px", // Increase height slightly for better scaling
    textAlign: "center",
    padding: "8px",
    display: "flex", // Flex to align the icon
    justifyContent: "center",
    alignItems: "center", // Align vertically and horizontally
  };

  const imgStyle = {
    width: adjustWidth, // Dynamic width from prop
    height: adjustHeight, // Dynamic height from prop
    objectFit: "contain", // Ensure the logo fits the box
  };

  const linkStyle = {
    textDecoration: "none",
    display: "inline-block",
  };

  return disableClick ? (
    <div style={cardStyle}>
      <img src={imageUrl} alt="Brand" style={imgStyle} />
    </div>
  ) : (
    <a
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={linkStyle}
    >
      <div style={cardStyle}>
        <img src={imageUrl} alt="Brand" style={imgStyle} />
      </div>
    </a>
  );
};

export default BrandCard;
