import React from "react";
import brand1 from "../images/brand_comex.png";
import brand2 from "../images/brand_inter.png";
import brand3 from "../images/brand_cemex.png";
import brand4 from "../images/brand_truper.png";

const BrandSection = () => {
  const brandContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 16px",
    flexWrap: "wrap",
  };

  const brandLogoStyle = {
    margin: "24px",
    maxWidth: "160px",
    opacity: 1,
    // opacity: 0.4, // Uncomment this line to make the brand logos less visible
    transition: "opacity 0.3s ease",
  };

  const brandLogoHoverStyle = {
    opacity: 1,
  };

  const isMobile = window.innerWidth <= 480;

  return (
    <div style={brandContainerStyle} data-aos="fade-up">
      {[brand1, brand2, brand3, brand4].map((brand, index) => (
        <img
          key={index}
          src={brand}
          alt={`Brand ${index + 1}`}
          style={{
            ...brandLogoStyle,
            maxWidth: isMobile ? "120px" : "160px",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.opacity = brandLogoHoverStyle.opacity;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.opacity = brandLogoStyle.opacity;
          }}
        />
      ))}
    </div>
  );
};

export default BrandSection;
