import React from "react";
import TestimonialCard from "./TestimonialCard";
import testimonialImage1 from "../images/testimonial1.jpg";
import testimonialImage2 from "../images/testimonial2.jpg";
import testimonialImage3 from "../images/testimonial3.jpg";

const TestimonialList = () => {
  const testimonials = [
    {
      name: "Alejandro García",
      testimonialImage: testimonialImage1,
      testimony:
        "La instalación de la luz y la plancha inteligente ha cambiado completamente mi experiencia en la cocina. Además, las ventanas negras le dan un toque sofisticado. ¡Ahora es el lugar perfecto para cocinar y disfrutar!",
      rating: 5,
    },
    {
      name: "Mariana López",
      testimonialImage: testimonialImage2,
      testimony:
        "Algunos días los trabajadores llegaron tarde, lo que alargó un poco el tiempo del proyecto, pero estoy muy feliz con el resultado final de la plancha inteligente con el sistema de extracción. ¡La cocina es hermosa y funcional!",
      rating: 4,
    },
    {
      name: "Carlos Méndez",
      testimonialImage: testimonialImage3,
      testimony:
        "Transformaron nuestra sala en un espacio que invita a relajarse con el mueble de madera y la instalación de la tele. La combinación de muebles y la luz natural hace que sea ideal para pasar tiempo en familia. ¡El resultado es espectacular!",
      rating: 5,
    },
  ];

  const sectionStyle = {
    display: "flex",
    justifyContent: "center",
    padding: "0 40px",
    gap: "80px",
    flexWrap: "wrap",
  };

  const mobileStyle = {
    flexDirection: "column",
    padding: "0 16px",
    gap: "16px",
    alignItems: "center",
  };

  return (
    <section
      style={
        window.innerWidth <= 480
          ? { ...sectionStyle, ...mobileStyle }
          : sectionStyle
      }
      data-aos="fade-up"
    >
      {testimonials.map((testimonial, index) => (
        <TestimonialCard
          key={index}
          name={testimonial.name}
          testimonialImage={testimonial.testimonialImage}
          testimony={testimonial.testimony}
          rating={testimonial.rating}
        />
      ))}
    </section>
  );
};

export default TestimonialList;
