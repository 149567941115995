import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import postalCodes from "../data/postal_codes_mexico_city.json";
import { useModal } from "../contexts/ModalContext";
import Colors from "../constants/Colors";

const PostalCodeInputField = () => {
  const [postalCode, setPostalCode] = useState("");
  const { openModal } = useModal();

  const handleInputChange = (e) => {
    const input = e.target.value;

    // Allow only numbers in the input field
    if (/^\d*$/.test(input)) {
      setPostalCode(input);
    }
  };

  const handleSearch = () => {
    const found = postalCodes.find((item) => item.postal_code === postalCode);

    if (found) {
      openModal(
        "¡Genial! El servicio está disponible en tu zona. ¡Estamos listos para ayudarte!",
        "success"
      );
    } else {
      openModal(
        "Lo lamentamos, aún no estamos disponibles en tu zona. ¡Estamos trabajando para llegar pronto a más lugares!",
        "failure"
      );
    }
    mixpanel.track("Postal Code Search", { postal_code_value: postalCode });
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "15px",
    overflow: "hidden",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginTop: "40px",
    marginRight: "16px",
    marginLeft: "16px",
  };

  const inputStyle = {
    flex: 1,
    border: "none",
    padding: "15px 20px",
    fontSize: "16px",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    outline: "none",
  };

  const buttonStyle = {
    backgroundColor:
      postalCode.length === 5 ? Colors.primary : Colors.primaryHalf,
    color: "#fff",
    border: "none",
    padding: "15px 25px",
    fontSize: "16px",
    cursor: postalCode.length === 5 ? "pointer" : "not-allowed",
    borderRadius: "15px",
    transition: "background-color 0.3s ease",
    whiteSpace: "nowrap",
  };

  const buttonHoverStyle = {
    backgroundColor:
      postalCode.length === 5 ? "#4028c9" : buttonStyle.backgroundColor,
  };

  const mobileButtonStyle = {
    ...buttonStyle,
    padding: "15px 10px",
    fontSize: "14px",
  };

  return (
    <div style={containerStyle}>
      <input
        type="text"
        placeholder="Introduce tu C.P."
        value={postalCode}
        onChange={handleInputChange}
        maxLength={5}
        style={inputStyle}
      />
      <button
        style={window.innerWidth < 480 ? mobileButtonStyle : buttonStyle}
        onClick={handleSearch}
        disabled={postalCode.length !== 5}
        onMouseOver={(e) => {
          if (postalCode.length === 5) {
            e.currentTarget.style.backgroundColor =
              buttonHoverStyle.backgroundColor;
          }
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
        }}
      >
        Comenzar
      </button>
    </div>
  );
};

export default PostalCodeInputField;
