import React, { useState } from "react";
import { Link } from "react-router-dom";
import Colors from "../constants/Colors";

const Logo = ({ src, altText = "IKADA", linkTo = "/" }) => {
  const [imageError, setImageError] = useState(false); // Track image load failure

  const handleError = () => {
    setImageError(true); // Set imageError to true if the image fails to load
  };

  return (
    <Link
      to={linkTo}
      style={{
        textDecoration: "none", // Remove underline from the link
      }}
    >
      {!imageError && src ? (
        <img
          src={src}
          alt={altText}
          style={{ height: "40px" }}
          onError={handleError}
        />
      ) : (
        <span
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: Colors.primary,
          }}
        >
          IKADA
        </span>
      )}
    </Link>
  );
};

export default Logo;
