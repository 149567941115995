import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import servicesData from "../data/services.json";
import serviceImage from "./service1.jpg";

const ServiceList = ({ categoryId }) => {
  const [filteredServices, setFilteredServices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (categoryId) {
      const servicesForCategory = servicesData.filter(
        (service) => service.category_id === parseInt(categoryId)
      );
      setFilteredServices(servicesForCategory);
    } else {
      setFilteredServices(servicesData); // Show all services if no category is selected
    }
  }, [categoryId]);

  const serviceListStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "16px",
    marginTop: "20px",
    padding: `0 ${isMobile ? "16px" : "40px"}`,
  };

  return (
    <div style={serviceListStyle} data-aos="fade-up">
      {filteredServices.map((service) => (
        <ServiceCard
          key={service.id}
          image={serviceImage}
          serviceName={service.name}
          serviceDescription={service.description}
          servicePrice={service.price}
        />
      ))}
    </div>
  );
};

export default ServiceList;
