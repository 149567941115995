import React from "react";
import mixpanel from "mixpanel-browser";

const WhatsAppButton = ({ number, message }) => {
  const handleClick = () => {
    const url = message
      ? `https://wa.me/${number}?text=${encodeURIComponent(message)}`
      : `https://wa.me/${number}`;
    window.open(url, "_blank");
    mixpanel.track("WhatsApp Button Clicked");
  };

  return (
    <button onClick={handleClick} style={buttonStyle}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp Logo"
        style={logoStyle}
      />
      <span style={textStyle}>Agenda por WhatsApp</span>
    </button>
  );
};

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  padding: "10px 25px",
  backgroundColor: "#25D366",
  color: "#fff",
  border: "none",
  borderRadius: "16px",
  cursor: "pointer",
  fontSize: "18px",
  fontWeight: "bold",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

const logoStyle = {
  height: "24px",
  width: "24px",
  marginRight: "10px",
};

const textStyle = {
  fontSize: "16px",
};

export default WhatsAppButton;
