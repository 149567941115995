import React, { useState, useEffect } from "react";

const SplitTitle = ({ normalText, boldText }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const splitTitleStyle = {
    fontFamily: '"Poppins", sans-serif',
    fontSize: "2rem",
    fontWeight: 400,
    color: "#31394f",
    textAlign: "center",
    margin: `${isMobile ? "40px" : "56px"} 16px`,
  };

  const boldStyle = {
    fontWeight: 700,
  };

  return (
    <h2 style={splitTitleStyle} data-aos="fade-up">
      {normalText} <span style={boldStyle}>{boldText}</span>
    </h2>
  );
};

export default SplitTitle;
