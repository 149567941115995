import React from "react";
import { Link } from "react-router-dom";

const LegalLinks = ({ isMobile }) => {
  const ulStyle = {
    listStyleType: "none",
    padding: 0,
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
  };

  const liStyle = {
    padding: isMobile ? "8px 0" : "0 32px",
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
  };

  const hoverStyle = {
    textDecoration: "underline",
  };

  return (
    <nav>
      <ul style={ulStyle}>
        <li style={liStyle}>
          <Link
            to="/terms-and-conditions"
            style={linkStyle}
            onMouseEnter={(e) =>
              (e.target.style.textDecoration = hoverStyle.textDecoration)
            }
            onMouseLeave={(e) =>
              (e.target.style.textDecoration = linkStyle.textDecoration)
            }
          >
            Términos y Condiciones
          </Link>
        </li>
        <li style={liStyle}>
          <Link
            to="/privacy-policy"
            style={linkStyle}
            onMouseEnter={(e) =>
              (e.target.style.textDecoration = hoverStyle.textDecoration)
            }
            onMouseLeave={(e) =>
              (e.target.style.textDecoration = linkStyle.textDecoration)
            }
          >
            Política de Privacidad
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default LegalLinks;
