import React, { useState, useRef } from "react";

const faqCardStyle = {
  fontFamily: '"Poppins", sans-serif',
  backgroundColor: "#f9f9f9",
  border: "1px solid #ddd",
  borderRadius: "5px",
  padding: "15px",
  margin: "10px 0",
  transition: "all 0.3s ease-in-out",
  overflow: "hidden",
  cursor: "pointer",
  display: "block",
};

const faqCardOpenStyle = {
  backgroundColor: "#eef2f7", // Change background when opened
};

const faqHeaderStyle = {
  display: "flex",
  alignItems: "center",
};

const iconStyle = {
  marginRight: "10px",
  width: "24px",
  height: "24px",
  transition: "transform 0.3s ease", // Add transition for animation
};

const iconOpenStyle = {
  transform: "rotate(180deg)", // Rotate 180 degrees when opened
};

const iconClosedStyle = {
  transform: "rotate(0deg)", // Reset rotation when closed
};

const faqContentStyle = (isOpen, contentRef) => ({
  overflow: "hidden",
  height: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
  opacity: isOpen ? 1 : 0,
  transition: "height 0.3s ease, opacity 0.3s ease",
});

const faqAnswerStyle = {
  padding: "10px 0",
};

const FaqCard = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{ ...faqCardStyle, ...(isOpen ? faqCardOpenStyle : {}) }}
      onClick={toggleOpen}
    >
      <div style={faqHeaderStyle}>
        <div
          style={{
            ...iconStyle,
            ...(isOpen ? iconOpenStyle : iconClosedStyle),
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
          >
            {isOpen ? (
              <path d="M200-440v-80h560v80H200Z" />
            ) : (
              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
            )}
          </svg>
        </div>
        <b>{question}</b>
      </div>
      <div style={faqContentStyle(isOpen, contentRef)} ref={contentRef}>
        <div style={faqAnswerStyle}>{answer}</div>
      </div>
    </div>
  );
};

export default FaqCard;
