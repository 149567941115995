import React, { useState, useEffect } from "react";
import hero1 from "../images/hero1.jpg";
import hero2 from "../images/hero2.jpg";
import hero3 from "../images/hero3.jpg";
import PostalCodeInputField from "./PostalCodeInputField";

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [heroImage, setHeroImage] = useState(hero1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const heroImages = [hero1, hero2, hero3];
    const randomImage =
      heroImages[Math.floor(Math.random() * heroImages.length)];

    setHeroImage(randomImage);
  }, []);

  const commonStyles = {
    fontFamily: '"Poppins", sans-serif',
    borderRadius: "24px",
    textShadow: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
  };

  const heroContainerStyle = {
    ...commonStyles,
    position: "relative",
    width: "100%",
    height: isMobile ? "40vh" : "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    margin: isMobile ? "0 16px" : "0 40px",
    marginTop: isMobile ? "16px" : "40px",
    backgroundImage: `url(${heroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  const heroOverlayStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "24px",
  };

  const heroContentStyle = {
    position: "relative",
    textAlign: "center",
    zIndex: 1,
    maxWidth: "800px",
    width: "100%",
    margin: "0 auto",
    padding: "20px",
  };

  const heroTitleStyle = {
    ...commonStyles,
    fontSize: isMobile ? "24px" : "48px",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  };

  const heroDescriptionStyle = {
    ...commonStyles,
    fontSize: isMobile ? "14px" : "24px",
    marginTop: "10px",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={heroContainerStyle} data-aos="fade-up">
        <div style={heroOverlayStyle}></div>
        <div style={heroContentStyle}>
          <h1 style={heroTitleStyle}>¡Servicios para tu Casa a la Medida!</h1>
          <p style={heroDescriptionStyle}>
            Descubre los mejores servicios profesionales para tu hogar. ¡Estamos
            listos para ayudarte!
          </p>
          <PostalCodeInputField />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
