import React, { useState, useEffect } from "react";

const EmailInputSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 480);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setEmail(input);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    setValidEmail(isValid);
  };

  const styles = {
    emailInputContainer: {
      fontFamily: '"Poppins", sans-serif',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      padding: isMobile ? "0 16px" : "0",
      paddingBottom: isMobile ? "16px" : "56px",
      width: "100%",
      boxSizing: "border-box",
    },
    emailInputWrapper: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "600px",
      boxSizing: "border-box",
      borderRadius: "15px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
      overflow: "hidden",
      backgroundColor: "#fff",
    },
    mailchimpForm: {
      background: "#fff",
      width: "100%",
    },
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.fnames = [];
    window.ftypes = [];
    window.fnames[0] = "EMAIL";
    window.ftypes[0] = "email";
  }, []);

  return (
    <div style={styles.emailInputContainer} data-aos="fade-up">
      <div
        style={{
          ...styles.emailInputWrapper,
          width: isMobile ? "100%" : "80%",
          padding: isMobile ? "0 16px" : "0",
        }}
      >
        <div id="mc_embed_shell">
          <link
            href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
            rel="stylesheet"
            type="text/css"
          />
          <div id="mc_embed_signup" style={styles.mailchimpForm}>
            <form
              action="https://ikada.us13.list-manage.com/subscribe/post?u=fe406771c8f7d3a6878cb8fd8&amp;id=39b3baa7f0&amp;f_id=00bdc2e1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate // Prevent Mailchimp or jQuery from adding validation
            >
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Dirección de correo electrónico
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    required
                    defaultValue={email} // Use defaultValue for uncontrolled input
                    onChange={handleInputChange} // Ensure onChange is handled
                  />
                </div>
                <div id="mce-responses" className="clear foot">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="text"
                    name="b_fe406771c8f7d3a6878cb8fd8_39b3baa7f0"
                    tabIndex="-1"
                    readOnly // Ensure this is read-only, not controlled by React
                    value=""
                  />
                </div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                      value="Suscribirse"
                      disabled={!validEmail}
                    />
                    <p style={{ margin: "0px auto" }}>
                      <a
                        href="http://eepurl.com/iY0UX6"
                        title="Mailchimp - email marketing made easy and fun"
                      >
                        <span
                          style={{
                            display: "inline-block",
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            className="refferal_badge"
                            src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                            alt="Intuit Mailchimp"
                            style={{
                              width: "220px",
                              height: "40px",
                              display: "flex",
                              padding: "2px 0px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailInputSection;
