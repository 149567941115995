import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import WhatsAppButton from "./WhatsAppButton";
import Logo from "./Logo";

import menuIcon from "../images/menu.svg";
import closeIcon from "../images/close.svg";

const MobileHeader = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const animateMenu = (open) => {
    const menu = menuRef.current;
    let start;
    const duration = 500;
    const initialPosition = open ? -100 : 0;
    const finalPosition = open ? 0 : -100;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      const newPosition =
        initialPosition + progress * (finalPosition - initialPosition);
      menu.style.transform = `translateY(${newPosition}%)`;
      menu.style.opacity = open ? progress : 1 - progress;

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        if (!open) {
          setTimeout(() => {
            menu.style.visibility = "hidden";
          }, 500);
        }
      }
    };

    if (open) {
      menu.style.visibility = "visible";
    }

    requestAnimationFrame(step);
  };

  const toggleMenu = () => {
    animateMenu(!menuOpen);
    setMenuOpen(!menuOpen);
  };

  const styles = {
    header: {
      height: "80px",
      fontFamily: '"Poppins", sans-serif',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 40px",
      backgroundColor: "#ffffff",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      boxShadow: menuOpen ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease",
    },
    headerLeft: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
    },
    logo: {
      height: "40px",
    },
    hamburgerIcon: {
      width: "30px",
      height: "30px",
      cursor: "pointer",
      transition: "transform 0.5s ease",
      transform: menuOpen ? "rotate(180deg)" : "rotate(0deg)", // Optional rotation
      filter:
        "invert(88%) sepia(1%) saturate(15%) hue-rotate(179deg) brightness(96%) contrast(86%)", // Light gray color filter
    },
    mobileMenu: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: "80px",
      left: 0,
      right: 0,
      width: "100%",
      backgroundColor: "white",
      zIndex: 999,
      transform: "translateY(-100%)",
      opacity: 0,
      visibility: "hidden",
      transition: "transform 0.5s ease, opacity 0.5s ease",
    },
    mobileMenuLink: {
      display: "block",
      margin: "16px 0",
      color: "rgba(49, 57, 79, 0.5)",
      fontWeight: "bold",
      textAlign: "center",
      width: "100%",
    },
    activeLink: {
      color: "rgba(49, 57, 79, 1)",
    },
    mobileCta: {
      marginTop: "24px",
      marginBottom: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
  };

  return (
    <header style={styles.header}>
      <div style={styles.headerLeft}>
        <Logo src="logo.png" altText="IKADA" linkTo="/" />
      </div>
      <img
        src={menuOpen ? closeIcon : menuIcon} // Switch between menu and close icon
        alt={menuOpen ? "Close menu" : "Open menu"}
        style={styles.hamburgerIcon}
        onClick={toggleMenu}
      />
      <div style={styles.mobileMenu} ref={menuRef}>
        <Link
          to="/services"
          style={{
            ...styles.mobileMenuLink,
            ...(location.pathname === "/services" ? styles.activeLink : {}),
          }}
          onClick={toggleMenu}
        >
          Servicios
        </Link>
        <Link
          to="/resultados"
          style={{
            ...styles.mobileMenuLink,
            ...(location.pathname === "/resultados" ? styles.activeLink : {}),
          }}
          onClick={toggleMenu}
        >
          Resultados
        </Link>
        <div style={styles.mobileCta}>
          <WhatsAppButton
            number="573004567890"
            message="¡Hola! ¿En qué podemos ayudarte?"
          />
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
