import React from "react";
import BrandCard from "./BrandCard";
import shieldIcon from "../images/shield.svg";
import lockIcon from "../images/lock.svg";
import masterCardIcon from "../images/mastercard.png";
import visaIcon from "../images/visa.png";
import paypalIcon from "../images/paypal.png";
import stripeIcon from "../images/stripe.png";

const PaymentMethods = ({ isMobile, disableClick = false }) => {
  const sectionStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const acceptedSecurePaymentStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: isMobile ? "16px" : "40px",
    marginBottom: "24px",
    padding: isMobile ? "0 8px" : "0 24px",
  };

  const dividerStyle = {
    height: "24px",
    width: "1px",
    backgroundColor: "#ccc",
    margin: isMobile ? "0 16px" : "0 40px",
  };

  const iconLabelStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
  };

  const paymentBrandContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? "16px" : "40px",
    margin: isMobile ? "16px 0" : "40px 0",
  };

  return (
    <>
      <div style={acceptedSecurePaymentStyle}>
        <div style={sectionStyle}>
          <img
            src={shieldIcon}
            alt="Shield"
            style={{ width: "24px", marginRight: "8px" }}
          />
          <span style={iconLabelStyle}>Métodos de Pago Aceptados</span>
        </div>

        <div style={dividerStyle}></div>

        <div style={sectionStyle}>
          <img
            src={lockIcon}
            alt="Lock"
            style={{ width: "24px", marginRight: "8px" }}
          />
          <span style={iconLabelStyle}>Pago Seguro</span>
        </div>
      </div>

      <div style={paymentBrandContainerStyle}>
        <BrandCard
          imageUrl={masterCardIcon}
          linkUrl="https://www.mastercard.com.mx/es-mx.html"
          disableClick={disableClick}
          adjustWidth="50%"
        />
        <BrandCard
          imageUrl={visaIcon}
          linkUrl="https://www.visa.com.mx/"
          disableClick={disableClick}
          adjustWidth="60%"
        />
        <BrandCard
          imageUrl={paypalIcon}
          linkUrl="https://www.paypal.com/mx/home"
          disableClick={disableClick}
          adjustWidth="40%"
        />
        <BrandCard
          imageUrl={stripeIcon}
          linkUrl="https://stripe.com/mx"
          disableClick={disableClick}
          adjustWidth="80%"
        />
      </div>
    </>
  );
};

export default PaymentMethods;
