import React from "react";
import instagramIcon from "../images/instagram.png";
import xIcon from "../images/x.png";
import tiktokIcon from "../images/tiktok.png";

const SocialMediaSection = ({ isMobile }) => {
  const socialIconContainerStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const socialIconsContainer = {
    display: "flex",
    justifyContent: "center",
    gap: isMobile ? "16px" : "40px",
    margin: isMobile ? "16px 0" : "40px 0",
  };

  const socialIconStyle = {
    width: "30px",
    height: "30px",
  };

  return (
    <div style={socialIconsContainer}>
      <a
        href="https://www.instagram.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={socialIconContainerStyle}>
          <img src={instagramIcon} alt="Instagram" style={socialIconStyle} />
        </div>
      </a>
      <a
        href="https://www.facebook.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={socialIconContainerStyle}>
          <img src={xIcon} alt="X" style={socialIconStyle} />
        </div>
      </a>
      <a
        href="https://www.tiktok.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={socialIconContainerStyle}>
          <img src={tiktokIcon} alt="TikTok" style={socialIconStyle} />
        </div>
      </a>
    </div>
  );
};

export default SocialMediaSection;
