import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalType, setModalType] = useState("");
  const [whatsMessageContent, setWhatsMessageContent] = useState("");

  const openModal = (content, type, whatsMessage) => {
    setModalContent(content);
    setModalType(type);
    setWhatsMessageContent(whatsMessage);
    setIsVisible(true);
  };

  const closeModal = () => {
    setIsVisible(false);
    setModalContent("");
    setModalType("");
    setWhatsMessageContent("");
  };

  return (
    <ModalContext.Provider
      value={{
        isVisible,
        modalContent,
        modalType,
        whatsMessageContent,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
