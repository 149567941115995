import React from "react";
import CoreValueCard from "./CoreValueCard";

import agentIcon from "../images/step_agent.svg";
import checkIcon from "../images/step_check.svg";
import construction from "../images/step_construction.svg";
import Colors from "../constants/Colors";

const CoreValuesList = () => {
  const coreValues = [
    {
      icon: checkIcon,
      title: "1. Selecciona Tu Servicio",
      description:
        "Escoge el servicio ideal para tu hogar de nuestro catálogo, desde diseño interior hasta plomería y jardinería, todo adaptado a tus necesidades específicas y preferencias.",
      circleColor: Colors.primary,
    },
    {
      icon: agentIcon,
      title: "2. Asignación de Agente",
      description:
        "Te asignaremos un agente personal que estará en contacto contigo para discutir los detalles del servicio, ajustar los requerimientos, y acordar la fecha y hora de realización del servicio.",
      circleColor: Colors.primary,
    },
    {
      icon: construction,
      title: "3. Realización del Servicio",
      description:
        "Antes de la fecha acordada, tu agente te enviará un enlace de pago para retener el monto del servicio. El profesional llegará a tu hogar para realizar el trabajo una vez confirmada la retención.",
      circleColor: Colors.primary,
    },
  ];

  const sectionStyle = {
    display: "flex",
    padding: "0 40px",
    justifyContent: "center",
    gap: "80px",
    flexWrap: "wrap",
  };

  const mobileStyle = {
    flexDirection: "column",
    padding: "0 16px",
    gap: "16px",
    alignItems: "center",
  };

  return (
    <section
      style={
        window.innerWidth <= 480
          ? { ...sectionStyle, ...mobileStyle }
          : sectionStyle
      }
      data-aos="fade-up"
    >
      {coreValues.map((value, index) => (
        <CoreValueCard
          key={index}
          icon={value.icon}
          title={value.title}
          description={value.description}
          circleColor={value.circleColor}
        />
      ))}
    </section>
  );
};

export default CoreValuesList;
