import React, { useEffect, useState } from "react";
import { useModal } from "../contexts/ModalContext";
import WhatsAppButton from "../components/WhatsAppButton";
import closeIcon from "../images/close_icon.svg";

const GlobalModal = () => {
  const {
    isVisible,
    modalContent,
    closeModal,
    modalType,
    whatsMessageContent,
  } = useModal();

  const [isAnimating, setIsAnimating] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Handle open/close animation
  useEffect(() => {
    if (isVisible) {
      setShowModal(true);
      setTimeout(() => setIsAnimating(true), 10); // Delay to trigger animation
    } else if (!isVisible && isAnimating) {
      setIsAnimating(false);
      setTimeout(() => setShowModal(false), 300); // Delay for fade out
    }
  }, [isVisible]);

  if (!showModal) return null;

  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    opacity: isAnimating ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  };

  const modalContentStyle = {
    position: "relative",
    backgroundColor: "white",
    padding: "30px",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: isAnimating ? "translateY(0)" : "translateY(-50px)",
    opacity: isAnimating ? 1 : 0,
    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const modalDescriptionStyle = {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#666",
    textAlign: "center",
  };

  const closeIconStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
  };

  const closeIconImageStyle = {
    width: "20px",
    height: "20px",
    filter:
      "invert(19%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(97%) contrast(90%)",
  };

  const whatsappButtonContainerStyle = {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  };

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <button onClick={closeModal} style={closeIconStyle}>
          <img src={closeIcon} alt="Close" style={closeIconImageStyle} />
        </button>
        <p style={modalDescriptionStyle}>{modalContent}</p>
        {modalType === "success" && (
          <div style={whatsappButtonContainerStyle}>
            <WhatsAppButton
              number="573004567890"
              message={whatsMessageContent}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalModal;
