import React from "react";

const LegalContent = ({ sections }) => {
  return (
    <div data-aos="fade-up">
      {sections.map((section, index) => (
        <div key={index}>
          {section.type === "subtitle" ? (
            <h2>{section.text}</h2>
          ) : (
            <p>{section.text}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default LegalContent;
