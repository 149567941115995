import React, { useState, useEffect } from "react";

import showcase_1 from "../images/showcase_1.jpg";
import showcase_2 from "../images/showcase_2.jpg";
import showcase_3 from "../images/showcase_3.jpg";
import showcase_4 from "../images/showcase_4.jpg";
import showcase_5 from "../images/showcase_5.jpg";
import showcase_6 from "../images/showcase_6.jpg";
import showcase_7 from "../images/showcase_7.jpg";
import showcase_8 from "../images/showcase_8.jpg";
import showcase_9 from "../images/showcase_9.jpg";
import showcase_10 from "../images/showcase_10.jpg";

const GallerySection = () => {
  const items = [
    { id: 1, image: showcase_1 },
    { id: 2, image: showcase_2 },
    { id: 3, image: showcase_3 },
    { id: 4, image: showcase_4 },
    { id: 5, image: showcase_5 },
    { id: 6, image: showcase_6 },
    { id: 7, image: showcase_7 },
    { id: 8, image: showcase_8 },
    { id: 9, image: showcase_9 },
    { id: 10, image: showcase_10 },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const galleryListStyle = {
    display: "flex",
    overflowX: "auto",
    padding: isMobile ? "16px" : "40px",
    gap: isMobile ? "16px" : "24px",
    scrollbarWidth: "none", // Hides the scrollbar for Firefox
  };

  const galleryCardStyle = {
    flexShrink: 0,
    width: "240px",
    height: "240px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Ensures the image stays within the border-radius
  };

  const galleryCardHoverStyle = {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  const galleryImageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensures the image covers the square and is cropped to fit
    display: "block", // Ensures the image behaves like a block-level element
    borderRadius: "15px", // Optional if you want the image itself to also have rounded corners
  };

  return (
    <div
      style={galleryListStyle}
      data-aos="fade-up"
      className="gallery-list" // Optional if you still want to maintain an AOS trigger
    >
      {items.map((item) => (
        <div
          key={item.id}
          style={galleryCardStyle}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = galleryCardHoverStyle.transform;
            e.currentTarget.style.boxShadow = galleryCardHoverStyle.boxShadow;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "none";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
          }}
        >
          <img
            src={item.image}
            alt={`Showcase ${item.id}`}
            style={galleryImageStyle}
          />
        </div>
      ))}
    </div>
  );
};

export default GallerySection;
