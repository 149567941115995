import React from "react";
import { useModal } from "../contexts/ModalContext";
import mixpanel from "mixpanel-browser";

const ServiceCard = ({
  image,
  serviceName,
  serviceDescription,
  servicePrice,
}) => {
  const { openModal } = useModal();
  const styles = {
    card: {
      fontFamily: '"Poppins", sans-serif',
      background: "#fff",
      overflow: "hidden",
      transition: "transform 0.3s",
      cursor: "pointer",
    },
    cardHover: {
      transform: "translateY(-5px)",
    },
    image: {
      borderRadius: "24px",
      width: "100%",
      height: "240px",
      objectFit: "cover",
    },
    content: {
      marginTop: "20px",
    },
    name: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      margin: 0,
    },
    description: {
      fontSize: "0.9rem",
      color: "#777",
      margin: "5px 0",
    },
    price: {
      fontSize: "1rem",
      color: "#6a1b9a",
      fontWeight: "bold",
    },
  };

  const handleCardClick = () => {
    const modalMessage = `¿Quieres agendar ${serviceName}?`;
    openModal(modalMessage, "success", serviceName);
    mixpanel.track("Service Card Clicked", {
      clicked_service_card: serviceName,
    });
  };

  return (
    <div
      className="service-card"
      style={styles.card}
      onClick={handleCardClick}
      onMouseOver={(e) =>
        (e.currentTarget.style.transform = styles.cardHover.transform)
      }
      onMouseOut={(e) => (e.currentTarget.style.transform = "none")}
    >
      <img src={image} alt={serviceName} style={styles.image} />
      <div style={styles.content}>
        <h3 style={styles.name}>{serviceName}</h3>
        <p style={styles.description}>{serviceDescription}</p>
        <p style={styles.price}>Desde ${servicePrice}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
