import React, { useEffect, useState } from "react";
import FaqCard from "./FaqCard";
import questionsData from "../data/questions_and_answers.json";

const FaqSection = () => {
  const [faqItems, setFaqItems] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    setFaqItems(questionsData);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sectionStyle = {
    padding: isMobile ? "0 16px 0" : "0 40px",
  };

  return (
    <div data-aos="fade-up" style={sectionStyle}>
      {faqItems.map((item, index) => (
        <FaqCard key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FaqSection;
