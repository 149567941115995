import React from "react";

const CoreValueCard = ({ icon, title, description, circleColor }) => {
  const cardStyle = {
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: "#fff",
    padding: "24px",
    borderRadius: "24px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const iconContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundColor: circleColor,
    flexShrink: 0,
  };

  const iconStyle = {
    width: "32px",
    height: "32px",
    filter: "brightness(0) invert(1)", // Make the icon white
  };

  const titleStyle = {
    marginTop: "32px",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#333",
  };

  const descriptionStyle = {
    fontSize: "0.9em",
    color: "#555",
  };

  return (
    <div style={cardStyle}>
      <div style={iconContainerStyle}>
        <img src={icon} alt={`${title} icon`} style={iconStyle} />
      </div>
      <h3 style={titleStyle}>{title}</h3>
      <p style={descriptionStyle}>{description}</p>
    </div>
  );
};

export default CoreValueCard;
