import React, { useEffect, useState } from "react";
import testimonialStar from "./testimonialStar.svg";

const TestimonialCard = ({ name, testimonialImage, testimony, rating }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    testimonialCard: {
      fontFamily: '"Poppins", sans-serif',
      backgroundColor: "#fff",
      padding: "16px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      maxWidth: isMobile ? "100%" : "250px", // Adjust maxWidth for mobile
    },
    testimonialContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    testimonialName: {
      fontSize: "1.2em",
      fontWeight: 600,
      color: "#333",
    },
    testimonialImage: {
      width: "100%",
      height: "auto",
      margin: "16px 0",
      borderRadius: "12px",
      objectFit: "cover",
    },
    testimonialText: {
      fontSize: "0.9em",
      color: "#555",
      margin: "16px 0",
    },
    testimonialRating: {
      marginTop: "24px",
    },
    starIcon: {
      fontSize: "1.2em",
    },
  };

  return (
    <div style={styles.testimonialCard}>
      <div style={styles.testimonialContent}>
        <h3 style={styles.testimonialName}>{name}</h3>
        <img
          src={testimonialImage}
          alt="testimonial work"
          style={styles.testimonialImage}
        />
        <p style={styles.testimonialText}>{testimony}</p>
        <div style={styles.testimonialRating}>
          {Array.from({ length: rating }, (_, index) => (
            <img
              key={index}
              src={testimonialStar}
              alt="star"
              style={styles.starIcon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
