import { useState, useEffect } from "react";
import LegalLinks from "./LegalLinks";
import SocialMediaSection from "./SocialMediaSection";
import PaymentMethods from "./PaymentMethods";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const footerStyle = {
    fontFamily: '"Poppins", sans-serif',
    fontSize: isMobile ? "12px" : "16px",
    backgroundColor: "#282c34",
    color: "white",
    textAlign: "center",
    padding: isMobile ? "16px 0" : "40px 0",
    width: "100%",
  };

  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 Ikada. Todos los derechos reservados.</p>
      <SocialMediaSection isMobile={isMobile} />
      <PaymentMethods isMobile={isMobile} />
      <LegalLinks isMobile={isMobile} />
    </footer>
  );
};

export default Footer;
