import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ title, description, image, alt, categoryId = 0 }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/services?categoryId=${categoryId}`);
  };

  const styles = {
    card: {
      fontFamily: '"Poppins", sans-serif',
      position: "relative",
      width: "240px",
      height: "440px",
      borderRadius: "24px",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      margin: "10px",
      display: "flex",
      alignItems: "flex-end",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      flexShrink: 0,
      backgroundImage: `url(${image})`,
    },
    cardHover: {
      transform: "translateY(-10px)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
    content: {
      background: "rgba(0, 0, 0, 0.5)",
      padding: "20px",
      width: "100%",
      color: "white",
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
    },
    title: {
      margin: 0,
      fontSize: "20px",
      fontWeight: "bold",
    },
    description: {
      margin: "10px 0 0",
      fontSize: "14px",
    },
  };

  return (
    <div
      style={styles.card}
      onClick={handleClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-10px)";
        e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      }}
    >
      <img src={image} alt={alt} style={{ display: "none" }} />
      <div style={styles.content}>
        <h2 style={styles.title}>{title}</h2>
        {/* <p style={styles.description}>{description}</p> */}
      </div>
    </div>
  );
};

export default CategoryCard;
